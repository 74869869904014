export const tabMenuSelDbs = () => {
  let tab = [
    { value: "s1g", label: "Gagnants du jour" },
    { value: "fv1", label: "1er favoris" },
    { value: "ec1", label: "Ecarts Drivers et Entraineurs" },
    { value: "ecn", label: "Ecarts Numéros gagnants" },
    { value: "cpr", label: "Chevaux Gagnant > à l'allocation" },
    { value: "sel", label: "Chevaux notes" }
  ];
  return tab;
}

export const tabMenuAdmin = (menuSelDbs) => {
  let tab = [
    { value: "ecJENP", label: "Les JENP" },
    { value: "cot", label: "Les Np + Cote partant à zéro" },
    { value: "arrRap0", label: "Arrivée course sans rapport" },
    { value: "pa2Arr0", label: "Arrivée partant sans rapport" }
  ];
  menuSelDbs = menuSelDbs.concat(tab);
  return menuSelDbs;
}

export const tabChampLib2_2 = (typTab) => {
  let tab = [];
  switch (typTab) {
    case "nameChamp":
      tab = [
        "cr2_heu",
        "hyp_nom",
        "cr2_qui",
        "cr2_hco",
        "cr2_hc2",
        "cr2_ca2",
        "cr2_prm",
        "cr2_di2",
        "cr2_nbp",
        "cr2_ar1eq",
        "cr2_rt1",
        "cr2_rt2",
        "cr2_rt3",
        "cr2_rt4",
        "cr2_rt5",
        "cr2_pro",
        "cr2_jmax2",
        "cr2_jord2",
        "cr2_jmax3",
        "cr2_jord3",
        "cr2_jmax4",
        "cr2_jord4",
        "cr2_pi3",
        "cr2_pi4",
        "cr2_j01",
        "ra2_drc",
      ];
      break;
    case "libChampCourt":
      tab = [
        "Réunion-Course-Heure",
        "Hippodrome",
        "Qté",
        "HCotFin",
        "HCotDeb",
        "Discipline",
        "Allocation",
        "Distance course",
        "Nb partants",
        "Arrivée-Rapport",
        "1",
        "2",
        "3",
        "4",
        "5",
        "pro",
        "m2",
        "o2",
        "m3",
        "o3",
        "m4",
        "o4",
        "pi3",
        "pi4",
        "Pronostic",
        "DRC",
      ];
      break;
    case "widChamp":
      tab = [
        "130px",
        "120px",
        "30px",
        "60px",
        "60px",
        "60px",
        "60px",
        "60px",
        "60px",
        "170px",
        "35px",
        "35px",
        "35px",
        "35px",
        "35px",
        "45px",
        "35px",
        "35px",
        "35px",
        "35px",
        "35px",
        "35px",
        "35px",
        "35px",
        "160px",
        "90px",
      ];
      break;
    case "libChampLong":
      tab = [
        "Réunion-Course-Heure",
        "Hippodrome",
        "Quinté",
        "Heure cote Fin",
        "Heure cote Début",
        "Discipline",
        "Allocation",
        "distance course",
        "Nb partants",
        "Arrivée-Rapport",
        "Rang(ordre de cote) du 1er arrivée",
        "Rang(ordre de cote) du 2e arrivée",
        "Rang(ordre de cote) du 3e arrivée",
        "Rang(ordre de cote) du 4e arrivée",
        "Rang(ordre de cote) du 5e arrivée",
        "pronostiqueur",
        "jmax 2",
        "jord 2",
        "jmax 3",
        "jord 3",
        "jmax 4",
        "jord 4",
        "Pi3",
        "Pi4",
        "DRC",
      ];
      break;
    default:
  }

  return tab;
};


export const tabChampLib3_2 = (typTab, jj, cr3expId) => {
  let tab = [];
  switch (typTab) {
    case "nameChamp":
      tab = [        
        "cr2_cat",
        "cr2_heu",
        "pa2_sel",
        "pa2_num",
        "che_nom",
        "che_nbc",
        "che_pog",
        "che_pop",
        "che_ecg",
        "che_ecp",
        "pa2_age",
        "pa2_sex",
        "pa2_def",
        "pa2_dis",
        "joc_nom",
        "joc_nbc",
        "joc_pog",
        "joc_pop",
        "joc_ecg",
        "joc_ecp",
        "ent_nom",
        "ent_nbc",
        "ent_pog",
        "ent_pop",
        "ent_ecg",
        "ent_ecp",
        "pa2_coteq",
        "pa2_faveq",
        "pa2_arreq",
        "ra2_s1geq",
        "ra2_plaeq",
        "pa2_pcp"
      ];
      break;
    case "libChampCourt":
      tab = [        
        "Cat",
        "Réunion-Course-Heure",
        "Sel",
        "N°",
        "Cheval",
        "Nbc",
        "%Ga",
        "%Pl",
        "Ecg",
        "Ecp",
        "Age",
        "Sex",
        "D/C",
        "D/P",
        "Driver",
        "Nbc",
        "%Ga",
        "%Pl",
        "Ecg",
        "Ecp",
        "Entraineur",
        "Nbc",
        "%Ga",
        "%Pl",
        "Ecg",
        "Ecp",
        "Coeq",
        "Faeq",
        "Areq",
        "Sgaeq",
        "Spleq",
        "Arr",
        "Sga",
        "Spl",
        "Pc"
      ];
      break;
    case "widChamp":
      tab = [        
        "30px",
        "120px",
        "30px",
        "30px",
        "150px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px",
        "120px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px",
        "120px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px",
        "25px"
      ];
      break;
    case "libChampLong":
      tab = [        
        "Discipline",
        "Réunion-course-heure",
        "Cheval sel",
        "Numéro",
        "Nom Cheval",
        "Nombre de couses courues cheval",
        "% de courses gagnées cheval",
        "% de courses placées cheval",
        "Ecart Nombre de courses depuis sa dernière gagne cheval",
        "Ecart Nombre de courses depuis sa dernière place (dans les 3 premiers) cheval",
        "Age du cheval",
        "Sexe du cheval",
        "Déferré pour l'attelé, Corde pour le plat",
        "Attelé=Distance que le cheval court (couleur marron avec le recul) --Plat=Poids",
        "Nom Driver ou Jockey",
        "Nombre de couses courues driver ou jockey",
        "% de courses gagnées driver ou jockey",
        "% de courses placées driver ou jockey",
        "Ecart Nombre de courses depuis sa dernière gagne driver ou jockey",
        "Ecart Nombre de courses depuis sa dernière place (dans les 3 premiers) driver ou jockey",
        "Nom Entraineur",
        "Nombre de couses courues entraineur",
        "% de courses gagnées entraineur",
        "% de courses placées entraineur",
        "Ecart Nombre de courses depuis sa dernière gagne entraineur",
        "Ecart Nombre de courses depuis sa dernière place (dans les 3 premiers) entraineur",
        "Rang Equ par ordre de cote",
        "Equi arrivé du cheval",
        "Equi Rapport simple Gagnant",
        "Equi Rapport simple Placé",
        "Turf arrivé du cheval",
        "Rapport simple Gagnant",
        "Rapport simple Placé",
      ];
      break;
    default:
  }
  if(cr3expId === 2) jj =2;
  return tab.slice(jj);
};

export const tabChampLib4_2 = (typTab) => {
  let tab = [];
  switch (typTab) {
    case "nameChamp":
      tab = [
        "pa2_che_id",
        'che_nom',
        'cr2_cat',
        'cr2_ca2',
        "cr2_dtm",
        "hyp_nom",
        "cr2_prm",
        "cr2_di2",
        "cr2_nbp",
        "pa2_age",
        "pa2_sex",
        "pa2_def",
        "pa2_dis",
        "joc_nom",
        "ent_nom",
        "pa2_num",
        "pa2_coteq",
        "pa2_faveq",
        "pa2_arreq",
        "ra2_s1geq",
        "ra2_plaeq"
      ];
      break;
    case "libChampCourt":
      tab = [
        "Code cheval",
        "Nom cheval",
        "Cat",
        "Ca2",
        "Date",
        "Hippodrome",
        "Prix",
        "distance course",
        "Nb partants",
        "Age",
        "Sex",
        "Defferé Corde",
        "Distance Poids",
        "Driver ou jockey",
        "Entraineur",
        "Num",
        "Coteq",
        "Faveq",
        "Arreq",
        "Sg1eq",
        "S1peq"
      ];
      break;
    case "widChamp":
      tab = [
        "80px",
        "120px",
        "40px",
        "40px",
        "120px",
        "180px",
        "70px",
        "70px",
        "70px",
        "50px",
        "50px",
        "50px",
        "80px",
        "160px",
        "140px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px",
        "50px"
      ];
      break;
    case "libChampLong":
      tab = [
        "Code cheval",
        "Nom cheval",
        "Discipline",
        "Ca2",
        "Date",
        "Hippodrome",
        "Allocation-Prix",
        "Distance de la course",
        "Nombre de partants",
        "Age du cheval",
        "Sexe du cheval",
        "Defferé pour l'attelé / Corde pour le plat",
        "Attelé=Distance que le cheval court (couleur marron avec le recul) --Plat=Poids",
        "Driver pour l'attelé / Jockey pour le plat et les haies",
        "Nom Entraineur",
        "Numéro",
        "Coteq approximative du cheval",
        "Faveq par ordre de cote",
        "Arreq de la place arrivé du cheval (maximum les 5 premiers)",
        "Rapport simple Gagnant",
        "Rapport simple Placé",
      ];
      break;
    default:
  }

  return tab;
};

export const tabChampLibRa4 = (typTab) => {
  let tab = [];
  switch (typTab) {
    case "nameChamp":
      tab = [
        "ra4_dtm",
        "ra4_typ",
        "ra4_cat",
        "hyp_nom",
        "ra4_num",
        "ra4_nbc",
        "ra4_nbg",
        "ra4_myg",
        "ra4_myc",
        "ra4_ecg",
        "ra4_egm",
        "ra4_ecx"
      ];
      break;
    case "libChampCourt":
      tab = [
        "Date",
        "Typ",
        "Cat",
        "Hippodrome",
        "Num",
        "Nbc",
        "Nbg",
        "Myg",
        "Mys",
        "Ecg",
        "Ecm",
        "Histo Ecx"
      ];
      break;
    case "widChamp":
      tab = [
        "90px",
        "30px",
        "30px",
        "140px",
        "40px",
        "40px",
        "40px",
        "50px",
        "50px",
        "40px",
        "40px",
        "160px"
      ];
      break;
    case "libChampLong":
      tab = [
        "Date",
        "Type",
        "Catégorie",
        "Hippodrome",
        "Numéro",
        "Nb de courses",
        "Nb de gagnants",
        "Moyenne de gagnant",
        "Moyenne rapport simple",
        "Nb écart gagnant",
        "Ecart max",
        ""
      ];
      break;
    default:
  }

  return tab;
};

const ra4typEntete = () => {
  let critere = "";
  return([
    <select onChange={(event) => {
      critere = event.target.value;
      return critere;
  }}>
    <option value="u">Num1</option>
    <option value="p">Alloc</option>
    </select>
  ,
  critere])
};

export const tabChampLibRa5 = (typTab) => {
  let tab = [];
  switch (typTab) {
    case "nameChamp":
      tab = [
        "ra4_dtm",
        "ra4_typ",
        "ra4_cat",
        "hyp_nom",
        "ra4_num",
        "ra4_nbc",
        "ra4_nbg",
        "ra4_myg",
        "ra4_myc",
        "ra4_ecg",
        "ra4_egm",
        "ra4_s1g",
        "ra4_s1c",
        "ra4_hej",
        "ra4_nc1",
        "ra4_nc2",
        "ra4_nc3",
        "ra4_ncj"
      ];
      break;
    case "libChampCourt":
      tab = [
        "Date",
        ra4typEntete(),
        "Cat",
        "Hippodrome",
        "Num",
        "Nbc",
        "Nbg",
        "Myg",
        "Mys",
        "Ecg",
        "Ecm",
        "Gain1",
        "GainC",
        "HcA1",
        "NcA1",
        "NbcC",
        "NbcT",
        "NbgJ"
      ];
      break;
    case "widChamp":
      tab = [
        "100px",
        "75px",
        "75px",
        "120px",
        "75px",
        "75px",
        "75px",
        "75px",
        "75px",
        "75px",
        "75px",
        "75px",
        "75px",
        "80px",
        "80px",
        "80px",
        "80px",
        "75px"
      ];
      break;
    case "libChampLong":
      tab = [
        "Date",
        "Type",
        "Catégorie",
        "Hippodrome",
        "Numéro",
        "Nb de courses",
        "Nb de gagnants",
        "Moyenne de gagnant",
        "Moyenne rapport simple",
        "Nb écart gagnant",
        "Ecart max",
        "Gain net au premier arrivée",
        "Gain net cumulé",
        "Heure course au premier arrivée",
        "Numéro course au premier arrivée",
        "Nb de courses courues",
        "Nb de courses à courir",
        "Nb de courses gagnées jour"
      ];
      break;
     
    default:
  }

  return tab;
};