import axios from "axios";

export const GET_RA2S = "GET_RA2S";
export const GET_RA2S_HYP = "GET_RA2S_HYP";
export const GET_RA2S_HYP_DATE = "GET_RA2S_HYP_DATE";
export const GET_RA2S_DATE = "GET_RA2S_DATE";

export const getRa2s = () => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/b_ra2`)
      .then((res) => {
        dispatch({ type: GET_RA2S, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getRa2sByHyp = (hyp) => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/b_ra2/hyp/${hyp}`)
      .then((res) => {
        dispatch({ type: GET_RA2S_HYP, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getRa2sByHypDate = (hyp) => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/b_ra2/hypdate/${hyp}`)
      .then((res) => {
        dispatch({ type: GET_RA2S_HYP_DATE, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getRa2sByDate = (dte) => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/b_ra2/date/${dte}`)
      .then((res) => {
        dispatch({ type: GET_RA2S_DATE, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

/* axios.get('/api', {
  params: {
    id: 123,
    name: 'John Doe'
  }
})
  .then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  }); */
