/* Logical OR (||)  Logical AND (&&)

 date - 1 jour
function getYesterdayDate() {
  return new Date(new Date().getTime() - 24*60*60*1000);
}
console.log(getYesterdayDate()); */

//require("dotenv").config({ path: "./.env" });

export const siHeu = (sChampValue) => {
  var dt_actu = new Date();
  if (process.env.NODE_ENV === "productionXX") {
    dt_actu.setHours(dt_actu.getHours() + 1);
    dt_actu.setMinutes(dt_actu.getMinutes() + 50);
  }

  var dt_crs = new Date();
  dt_crs.setHours(parseInt(sChampValue.substr(0, 2)));
  dt_crs.setMinutes(parseInt(sChampValue.substr(2, 2)));
  dt_crs.setSeconds(0);
  var total_secondes = (dt_crs - dt_actu) / 1000;

  if (total_secondes > 0) {
    var jours = Math.floor(total_secondes / (60 * 60 * 24));
    var heures = Math.floor(
      (total_secondes - jours * 60 * 60 * 24) / (60 * 60)
    );
    var minutes = Math.floor(
      (total_secondes - (jours * 60 * 60 * 24 + heures * 60 * 60)) / 60
    );
    var secondes = Math.floor(
      total_secondes - (jours * 60 * 60 * 24 + heures * 60 * 60 + minutes * 60)
    );

    if (sChampValue < "1005") {
      var iMaxMinutes = 15;
      var iMaxHeures = 1;
    } else {
      iMaxMinutes = 20;
      iMaxHeures = 1;
    }

    var compteArebours = "";
    var sIndHeu = "";
    if (minutes < iMaxMinutes && heures < iMaxHeures) {
      sIndHeu = "O";
      //console.log('total_secondes:'+total_secondes+'secondes:'+secondes);
      var prefixe = "Départ dans ";
      var mot_minute = "mn,";
      var mot_seconde = "s";
      if (minutes === 0) {
        minutes = "";
        mot_minute = "";
      } else if (minutes === 1) {
        mot_minute = "mn,";
      }
      if (secondes === 0) {
        secondes = "";
        mot_seconde = "";
      } else if (secondes === 1) mot_seconde = "s";
      //compteArebours = prefixe + jours + ' ' + mot_jour + ' ' + heures + ' ' + mot_heure + ' ' + minutes + ' ' + mot_minute + ' ' + et + ' ' + secondes + ' ' + mot_seconde;
      compteArebours =
        prefixe +
        " " +
        minutes +
        " " +
        mot_minute +
        " " +
        secondes +
        " " +
        mot_seconde;
      return [sIndHeu, compteArebours];
    }
    return [sIndHeu, compteArebours];
  }
  return [sIndHeu, compteArebours];
};

export const siProno = (oCr2) => {
  var libProno = [];
  const iMax = oCr2["cr2_nbp"] < 8 ? oCr2["cr2_nbp"] : 8;
  // Générer dynamiquement les valeurs à vérifier en fonction de count
  var tNumJeuToCheck = [];
  for (let j = 1; j <= iMax; j++) {
    tNumJeuToCheck.push(oCr2["cr2_j0" + j]);
  }

  if (oCr2.cr2_ar1eq > 0) {
    if (oCr2.cr2_qui === "O") {
      var count = 5;
    } else {
      count = 4;
    }

    // Générer dynamiquement les valeurs à vérifier en fonction de count
    var tNumArrToMatch = [];
    for (let j = 1; j <= count; j++) {
      tNumArrToMatch.push(oCr2["cr2_ar" + j + "eq"]);
    }

    if (oCr2.cr2_j01 > 0) {
      var allMatch = true;
      for (var j = 0; j < count; j++) {
        var matchFound = false;
        for (var i = 0; i < iMax; i++) {
          if (tNumArrToMatch[j] === tNumJeuToCheck[i]) {
            matchFound = true;
            break;
          }
        }
        if (!matchFound) {
          allMatch = false;
          break;
        }
      }
      if (allMatch) {
        for (let i = 0; i < tNumJeuToCheck.length; i++) {
          if (tNumJeuToCheck[i] !== null) {
            let index = tNumArrToMatch.findIndex(
              (element) => element === tNumJeuToCheck[i]
            );
            index++;
            //console.log(index);
            if (index > 0) {
              libProno.push(
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {tNumJeuToCheck[i]}-
                </span>
              );
            } else {
              libProno.push(<span>{tNumJeuToCheck[i]}-</span>);
            }
          }
        }
      }else{
        libProno.push(<span>{tNumJeuToCheck.join("-")}</span>);
      }
    }
  } else {
    libProno.push(<span>{tNumJeuToCheck.join("-")}</span>);
  }
  return libProno;
};

export const siDateJour = (dat) => {
  const date = new Date(dat);
  const today = new Date();

  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    //console.log('La date est égale à la date du jour!');
    return true;
  } else {
    //console.log('La date n\'est pas égale à la date du jour.');
    return false;
  }
};

export const siUserMe = (user) => {
  if (isEmpty(user)) {
    return false;
  } else {
    if (user.name === "Nico_free") {
      return true;
    } else {
      return false;
    }
  }
};

export const dateYMD = (dat, jj) => {
  if (dat !== undefined) {
    var datx = new Date(dat);
    let dd = 0;
    datx.setDate(datx.getDate() + jj);
    dd = datx.toISOString().slice(0, 10);
    return dd;
  }
};

export const cr3LibCrs = (cr3Exp, cr2oura2) => {
  if (cr2oura2 === "cr2") {
    cr3Exp[2].label =
      "La course du: " +
      dateJJMMAAAA(cr3Exp[0].data.cr2_dtm) +
      " - " +
      catAHP(cr3Exp[0].data.cr2_cat) +
      " - " +
      cr3Exp[0].data.hyp_nom +
      " - " +
      cr3Exp[0].data.cr2_drc.substr(6, 5) +
      " à " +
      heuCrs(cr3Exp[0].data.cr2_heu.toString()) +
      " - " +
      cr3Exp[0].data.cr2_prm +
      "€ - " +
      cr3Exp[0].data.cr2_di2 +
      "m " +
      " -Nb partants:" +
      cr3Exp[0].data.cr2_nbp;
  }
  if (cr2oura2 === "ra2") {
    cr3Exp[2].label =
      "La course du: " +
      dateJJMMAAAA(cr3Exp[0].data.cr2_dtm) +
      " - " +
      catAHP(cr3Exp[0].data.cr2_cat) +
      " - " +
      cr3Exp[0].data.hyp_nom +
      " - " +
      cr3Exp[0].data.ra2_drc.substr(6, 5) +
      " à " +
      heuCrs(cr3Exp[0].data.cr2_heu.toString()) +
      " - " +
      cr3Exp[0].data.cr2_prm +
      "€ - " +
      cr3Exp[0].data.cr2_di2 +
      "m " +
      " -Nb partants:" +
      cr3Exp[0].data.cr2_nbp;
  }

  return cr3Exp[2].label;
};

// Créer une fonction qui vérifie si une valeur est présente dans un tableau
export const siPa2arr0 = (tab, valeur) => {
  // Parcourir le tableau avec une boucle for
  for (var i = 0; i < tab.length; i++) {
    // Si la valeur est égale à l'élément du tableau à l'indice i, renvoyer true
    if (valeur === tab[i]) {
      return true;
    }
  }
  // Si la valeur n'a pas été trouvée dans le tableau, renvoyer false
  return false;
};

export const dateParser = (num) => {
  let options = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  let timestamp = Date.parse(num);
  let date = new Date(timestamp).toLocaleDateString("fr-FR", options);
  return date.toString();
};

export const timestampParser = (num) => {
  let options = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  let date = new Date(num).toLocaleDateString("fr-FR", options);
  return date.toString();
};

export const dateJJMMAAAA = (num) => {
  let options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  let date = new Date(num).toLocaleDateString("fr-FR", options);
  return date.toString();
};

export const dateAAAAMMJJ = (num) => {
  let options = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };
  let dateCalcular = new Date(num).toLocaleDateString("fr-FR", options);
  return dateCalcular.toString();
};

export const isColor = (mt) => {
  let sColor = "";
  if (mt > 0 && mt <= 5) {
    sColor = "#9fcf9f";
  } else if (mt > 5 && mt <= 10) {
    sColor = "#7fbf7f";
  } else if (mt > 10 && mt <= 20) {
    sColor = "#5faf5f";
  } else sColor = "#1f8f1f";

  return sColor;
};

export const numDecimal = (num) => {
  if (num !== null) {
    return num.toFixed(1);
  }
};

export const isArrPla = (siArr, s1p, s2p, s3p) => {
  if (siArr === "1" || siArr === "01") return s1p;
  if (siArr === "2" || siArr === "02") return s2p;
  if (siArr === "3" || siArr === "03") return s3p;
};

export const isArrGag = (siArr, s1g) => {
  if (siArr === "1" || siArr === "01") return s1g;
};

export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const catAHP = (cat) => {
  let catLong = "";
  switch (cat) {
    case "a":
      catLong = "Attelé";
      break;
    case "m":
      catLong = "Monté";
      break;
    case "h":
      catLong = "Haie";
      break;
    case "c":
      catLong = "Cross";
      break;
    case "s":
      catLong = "Steeple";
      break;
    case "p":
      catLong = "Plat";
      break;
    default:
  }

  return catLong;
};

export const ra4Ecx = (ecx) => {
  let ecxCourt = "";
  if (ecx > "") {
    ecxCourt = ecx.substr(0, 20);
  }
  return ecxCourt;
};

export const heuCrs = (heu) => {
  let heuLong = "";
  if (heu.length === 3) {
    heuLong = "0" + heu.substr(0, 1) + "h" + heu.substr(-2);
  } else {
    heuLong = heu.substr(0, 2) + "h" + heu.substr(-2);
  }
  return heuLong;
};

export const libQui = (qui) => {
  let quiLong = "";
  switch (qui) {
    case "O":
      quiLong = "Qté";
      break;
    default:
  }

  return quiLong;
};

export const spiSearch = (si2o3, t1, drc, num) => {
  // parametre tableau/drc/num
  let drcNum = "";
  switch (si2o3) {
    case "J":
      drcNum = drc.concat("J" + num);
      if (t1.indexOf(drcNum) > -1) {
        return true;
      } else {
        return false;
      }
    case "E":
      drcNum = drc.concat("E" + num);
      if (t1.indexOf(drcNum) > -1) {
        return true;
      } else {
        return false;
      }
    case "C":
      drcNum = drc.concat("C" + num);
      if (t1.indexOf(drcNum) > -1) {
        return true;
      } else {
        return false;
      }
    default:
  }
};
