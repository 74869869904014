import { siPa2arr0, spiSearch } from "./Utils";

// reqCr2det(data1, user);
export const reqCr2User = (data0, user) => {
  //let userVar = [];
  let jsonx = "";
  //let jsonx = JSON.stringify(user);
  for (let h = 0; h < data0.length; h++) {
    if (user.name === data0[h].name) break;
    jsonx = JSON.stringify(user);
  }
  if (jsonx > "") {
    data0.push(JSON.parse(jsonx));
    return data0;
  }
};

// reqHypItems(data2, 'dd');
export const reqHypItems = (hyps, critere1) => {
  let itemsHypVar = [];
  for (let h = 0; h < hyps.length; h++) {
    //if (critere1 === "j") {
    if (hyps[h].hyp_dtg === critere1) {
      itemsHypVar.push(hyps[h]);
    }
    //}
  }
  return itemsHypVar;
};

// reqRa4Items(data2, 'r');
export const reqRa4Items = (data2, critere1) => {
  let itemsRa4Var = [];
  for (let h = 0; h < data2.length; h++) {
    if (data2[h].ra4_hyp_id === critere1) {
      itemsRa4Var.push(data2[h]);
    }
  }
  return itemsRa4Var;
};

// reqCr2par(data1, selectedOption);
export const reqCr2par = (data1, critere1) => {
  let itemsMenuSelectedVar = [];
  let numTrouveVar = [];
  for (let i = 0; i < data1.length; i++) {
    //----------------------------admin
    if (critere1.value === "cot") {
      if (data1[i].pa2_arreq === 'Z8' || data1[i].pa2_coteq === 999.9 || data1[i].pa2_coteq === 0) {
        itemsMenuSelectedVar.push(data1[i]);
        numTrouveVar.push(data1[i].pa2_drc + critere1.value + data1[i].pa2_num);
      }
    }

    //--------On ne prends pas les Non partants
    //if (data1[i].pa2_joc_id !== 114 || data1[i].pa2_ent_id !== 15 || data1[i].pa2_arreq === 'Z8' || data1[i].pa2_coteq === 999.9) {
    if (data1[i].pa2_arreq !== 'Z8' || data1[i].pa2_coteq !== 999.9) {
      //-------------------------admin
      if (critere1.value === "pa2Arr0") {
        let tabArr = [data1[i].cr2_ar1eq, data1[i].cr2_ar2eq, data1[i].cr2_ar3eq];
        if (
          data1[i].pa2_arreq > '' &&
          data1[i].pa2_arreq < '04' &&
          !siPa2arr0(tabArr, data1[i].pa2_num)
        ) {
          itemsMenuSelectedVar.push(data1[i]);
          numTrouveVar.push(data1[i].pa2_drc + critere1.value + data1[i].pa2_num);
        }
      }

      //-------------------------utilisateur

      if (critere1.value === "fv1") {
        if (data1[i].pa2_faveq === 1) {
          itemsMenuSelectedVar.push(data1[i]);
          numTrouveVar.push(data1[i].pa2_drc + critere1.value + data1[i].pa2_num);
        }
      }
      if (critere1.value === "s1g") {
        if (data1[i].pa2_arreq === 1) {
          itemsMenuSelectedVar.push(data1[i]);
          numTrouveVar.push(data1[i].pa2_drc + critere1.value + data1[i].pa2_num);
        }
      }
      if (critere1.value === "ec1") {
        if ((data1[i].pa2_ecj === 'j') || (data1[i].pa2_ece === 'e')) {
          itemsMenuSelectedVar.push(data1[i]);
          numTrouveVar.push(data1[i].pa2_drc + critere1.value + data1[i].pa2_num);
        }
      }
      if (critere1.value === "ecn") {
        if (data1[i].pa2_ecn === 'n') {
          itemsMenuSelectedVar.push(data1[i]);
          numTrouveVar.push(data1[i].pa2_drc + critere1.value + data1[i].pa2_num);
        }
      }
      if (critere1.value === "cpr") {
        if (data1[i].pa2_cpr === 'p') {
          itemsMenuSelectedVar.push(data1[i]);
          numTrouveVar.push(data1[i].pa2_drc + critere1.value + data1[i].pa2_num);
        }
      }
      if (critere1.value === "sel") {
        if (data1[i].pa2_sel === 'O') {
          itemsMenuSelectedVar.push(data1[i]);
          numTrouveVar.push(data1[i].pa2_drc + critere1.value + data1[i].pa2_num);
        }
      }
      if (critere1.value === "ecJENP") {
        if ((data1[i].pa2_ecj === 'j') || (data1[i].pa2_ece === 'e')
          || (data1[i].pa2_ecn === 'n') || (data1[i].pa2_cpr === 'p')
          || (data1[i].pa2_sel === 'O')) {
          itemsMenuSelectedVar.push(data1[i]);
          numTrouveVar.push(data1[i].pa2_drc + critere1.value + data1[i].pa2_num);
        }
      }
    }
  }
  return [itemsMenuSelectedVar, numTrouveVar];
};


// reqCr2parSearch(jec, data1, searchValue);
export const reqCr2parSearch = (jec, data1) => {
  let numTrouveVar = [];

  for (let i = 0; i < data1.length; i++) {
    // parametre tableau/drc/num
    let siDrcNum = spiSearch(
      jec,
      numTrouveVar,
      data1[i].pa2_drc,
      data1[i].pa2_num
    );
    if (siDrcNum === false) {
      numTrouveVar.push(data1[i].pa2_drc + jec + data1[i].pa2_num);
    }
  }
  return numTrouveVar;
};

// reqCr2parSearch(jec, data1, searchValue);
export const reqCr2parSearchSuppLevel = (data1) => {
  let itemTrouveVar = [];
  for (let i = 0; i < data1.length; i++) {
    for (let j = 0; j < data1[i].length; j++) {
      itemTrouveVar.push(data1[i][j]);
    }
  }
  return itemTrouveVar;
};
