import axios from "axios";

export const GET_HYP = "GET_HYP";
export const GET_HYPS = "GET_HYPS";
export const GET_HYPS_DATE = "GET_HYPS_DATE";

//$$$$$ gn$$$ modif url
//En raison de notre situation, le frontend et le backend sont tous deux à la même adresse, nous pouvons déclarer baseUrl comme une URL relative. Cela signifie que nous pouvons laisser de côté la partie déclarant le serveur. 

export const getHyps = () => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/a_hyp`)
      .then((res) => {
        dispatch({ type: GET_HYPS, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
//--------
/* export const initializeNotes = () => {
  return async dispatch => {
    const notes = await noteService.getAll()
    dispatch(setNotes(notes))
  }
}
const getAll = async () => {
  const response = await axios.get(baseUrl)
  return response.data
} */
//--------------

export const getHypById = (uid) => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/a_hyp/${uid}`)
      .then((res) => {
        dispatch({ type: GET_HYP, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getHypsByDate = (hyp_dtm) => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/a_hyp/date/${hyp_dtm}`)
      .then((res) => {
        dispatch({ type: GET_HYPS_DATE, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};
