import axios from "axios";

export const GET_RA5 = "GET_RA5";
export const GET_RA5S = "GET_RA5S";
export const DEL_RA5 = "DEL_RA5";

export const getRa5s = () => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/b_ra5`)
      .then((res) => {
        dispatch({ type: GET_RA5S, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getRa5ById = (id) => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/b_ra5/${id}`)
      .then((res) => {
        dispatch({ type: GET_RA5, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
};

export const delRa5ById = (id) => {
  return (dispatch) => {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}api/b_ra5/${id}`)
      .then((res) => {
        dispatch({ type: DEL_RA5, payload: {id} });
      })
      .catch((err) => console.log(err));
  };
};
