import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { tabChampLib2_2 } from "./Tableaux";
import { customStyles } from "./StylesDataTable";
import Cr3Compo from "./Cr3Compo";
import {
  siDateJour,
  dateJJMMAAAA,
  heuCrs,
  catAHP,
  libQui,
  isEmpty,
  numDecimal,
  siHeu,
  siProno,
  siUserMe,
} from "./Utils";
import { useAuth0 } from "@auth0/auth0-react";

const Cr2Compo = ({ ra2s }) => {
  const { user, isAuthenticated } = useAuth0();
  const [rapPopup, setRapPopup] = useState(false);
  const [cr2R, setCr2R] = useState([]);

  const [libHeu, setLibHeu] = useState();
  const [crsHeu, setCrsHeu] = useState();

  //Chargement tableaux
  const nameChamp = useMemo(() => tabChampLib2_2("nameChamp"), []);
  const libChampCourt = useMemo(() => tabChampLib2_2("libChampCourt"), []);
  const widChamp = useMemo(() => tabChampLib2_2("widChamp"), []);
  const tabSortable = useMemo(
    () => Array.from(nameChamp, () => true),
    [nameChamp]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      let siHeuOut = [];
      const siHeuFunc = () => {
        for (let i = 0; i < ra2s[0].length; i++) {
          if (
            ra2s[0][i].cr2_ar1eq === 0 &&
            siDateJour(ra2s[0][0].cr2_dtm) &&
            isEmpty(siHeuOut[0])
          ) {
            let cr2Heu = ra2s[0][i].cr2_heu.toString();
            siHeuOut = siHeu(cr2Heu);
            if (siHeuOut[0] === "O") {
              setLibHeu(siHeuOut[1]);
              setCrsHeu(ra2s[0][i].ra2_drc);
              break;
            }
          }
        }
      };
      siHeuFunc();
    }, 500000);

    return () => clearInterval(intervalId);
  }, [ra2s, libHeu]);

  let laCrsDu = { id: 1, value: "3", label: "La course du: " };

  let iLibChampLongMax = nameChamp.length;
  let userMeOut = isAuthenticated && siUserMe(user);
  if (userMeOut === false) iLibChampLongMax = iLibChampLongMax - 2;
  const columns = Array.from({ length: iLibChampLongMax }, (v, i) => {
    return {
      name: libChampCourt[i],
      selector: (row) => row[nameChamp[i]],

      cell: (row) => {
        if (
          nameChamp[i] === "cr2_heu" &&
          (siDateJour(row.cr2_dtm) || row.cr2_dtm > "2029-12-31")
        ) {
          return (
            <div className="card-container_j">
              <button>
                {row.cr2_reu}-C{row.cr2_crs} à {heuCrs(row.cr2_heu.toString())}
              </button>
            </div>
          );
        } else if (nameChamp[i] === "cr2_pi3") {
          if (row.cr2_pi3 >= 20) {
            return (
              <p style={{ color: i >= 0 ? "red" : "black" }}>{row.cr2_pi3}</p>
            );
          } else {
            return (
              <p style={{ color: i >= 0 ? "black" : "red" }}>{row.cr2_pi3}</p>
            );
          }
        } else if (nameChamp[i] === "cr2_pi4") {
          if (row.cr2_pi4 >= 30) {
            return (
              <p style={{ color: i >= 0 ? "red" : "black" }}>{row.cr2_pi4}</p>
            );
          } else {
            return (
              <p style={{ color: i >= 0 ? "black" : "red" }}>{row.cr2_pi4}</p>
            );
          }
        } else if (nameChamp[i] === "cr2_rt1") {
          if (row.cr2_rt1 === 0) {
            return (
              <p style={{ background: i >= 0 ? "yellow" : "black" }}>
                {row.cr2_rt1}
              </p>
            );
          } else if (row.cr2_rt1 === 1) {
            return (
              <p style={{ background: i >= 0 ? "red" : "black" }}>
                {row.cr2_rt1}
              </p>
            );
          } else if (row.cr2_rt1 === 2) {
            return (
              <p
                style={{
                  background: i >= 0 ? "blue" : "black",
                }}
              >
                {row.cr2_rt1}
              </p>
            );
          } else {
            return (
              <p style={{ color: i >= 0 ? "black" : "red" }}>{row.cr2_rt1}</p>
            );
          }
        } else if (nameChamp[i] === "cr2_rt2") {
          if (row.cr2_rt2 === 0) {
            return (
              <p
                style={{
                  background: i >= 0 ? "yellow" : "black",
                }}
              >
                {row.cr2_rt2}
              </p>
            );
          } else if (row.cr2_rt2 === 1) {
            return (
              <p style={{ background: i >= 0 ? "red" : "black" }}>
                {row.cr2_rt2}
              </p>
            );
          } else if (row.cr2_rt2 === 2) {
            return (
              <p
                style={{
                  background: i >= 0 ? "blue" : "black",
                }}
              >
                {row.cr2_rt2}
              </p>
            );
          } else {
            return (
              <p style={{ color: i >= 0 ? "black" : "red" }}>{row.cr2_rt2}</p>
            );
          }
        } else if (nameChamp[i] === "cr2_rt3") {
          if (row.cr2_rt3 === 0) {
            return (
              <p
                style={{
                  background: i >= 0 ? "yellow" : "black",
                }}
              >
                {row.cr2_rt3}
              </p>
            );
          } else if (row.cr2_rt3 === 1) {
            return (
              <p
                style={{
                  background: i >= 0 ? "red" : "black",
                }}
              >
                {row.cr2_rt3}
              </p>
            );
          } else if (row.cr2_rt3 === 2) {
            return (
              <p
                style={{
                  background: i >= 0 ? "blue" : "black",
                }}
              >
                {row.cr2_rt3}
              </p>
            );
          } else {
            return (
              <p style={{ color: i >= 0 ? "black" : "red" }}>{row.cr2_rt3}</p>
            );
          }
        } else if (nameChamp[i] === "cr2_heu" && !siDateJour(row.cr2_dtm)) {
          return (
            <div className="card-container_h">
              <button>
                {dateJJMMAAAA(row.cr2_dtm)}-{row.cr2_reu}-C
                {row.cr2_crs}
              </button>
            </div>
          );
        } else if (nameChamp[i] === "cr2_ar1eq") {
          return (
            row.cr2_ar1eq > 0 && (
              <div className="card-container_r">
                <button
                  className="btn btn-success"
                  onClick={() => handleClickRap(row.ra2_drc)}
                >
                  {row.ra2_s1geq > 0 ? "Rap:" : "Arr:"} {row.cr2_ar1eq}-
                  {row.cr2_ar2eq}-{row.cr2_ar3eq}-{row.cr2_ar4eq}-
                  {row.cr2_ar5eq}
                </button>
              </div>
            )
          );
        } else if (nameChamp[i] === "cr2_j01") {
          return isAuthenticated && siUserMe(user) && siProno(row);
        } else if (nameChamp[i] === "cr2_qui") {
          return (
            <p
              style={{
                color: i >= 0 ? "red" : "black",
              }}
            >
              {libQui(row.cr2_qui)}
              {libHeu > "" && row.ra2_drc === crsHeu ? libHeu : ""}
            </p>
          );
        } else if (nameChamp[i] === "cr2_ca2") {
          return catAHP(row.cr2_ca2);
        } else {
          return row[nameChamp[i]];
        }
      },

      sortable: tabSortable[i],
      width: widChamp[i],
    };
  });

  const handleClickRap = (ra2Drc) => {
    for (let lig = 0; lig < ra2s[0].length; lig++) {
      if (ra2s[0][lig].ra2_drc === ra2Drc) {
        if (rapPopup === true) {
          setRapPopup(false);
        } else {
          setRapPopup(true);
        }
        setCr2R(ra2s[0][lig]);
        break;
      }
    }
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Lignes par page",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Tous",
    //paginationPerPage: 20,
    // paginationRowsPerPageOptions: [20, 30, 40, 50]
  };

  const fixedHeader = {
    fixedHeader: true,
    fixedHeaderScrollHeight: "100px",
  };

  return (
    !isEmpty(ra2s[0]) && (
      <>
        <DataTable
          title={
            !isEmpty(ra2s[2])
              ? "Les courses du " +
                dateJJMMAAAA(ra2s[0][0].cr2_dtm) +
                " + Historique " +
                ra2s[0][0].hyp_nom
              : "Les courses du " + dateJJMMAAAA(ra2s[0][0].cr2_dtm)
          }
          data={ra2s[0]}
          highlightOnHover
          pointerOnHover
          columns={columns}
          customStyles={customStyles}
          fixedHeader={fixedHeader}
          defaultSortField="cr2_heu"
          striped
          responsive
          pagination
          paginationComponentOptions={paginationComponentOptions}
          paginationRowsPerPageOptions={[10, 40, 60, 100]}
          paginationPerPage={40}
          noDataComponent="Aucun résultat"
          expandableRows
          expandableRowsComponent={(row) => (
            <Cr3Compo cr3Exp={[row, ra2s, laCrsDu]} />
            //<ExpandedComponent cr3Exp={[row]} />
          )}
        />

        {rapPopup && !isEmpty(cr2R) && (
          <div className="popup-profil-container">
            <div className="modal">
              <h3>{cr2R.hyp_nom}</h3>
              <h3>
                Rapports: {cr2R.cr2_reu}-C{cr2R.cr2_crs} du{" "}
                {dateJJMMAAAA(cr2R.cr2_dtm)}
              </h3>
              <span className="cross" onClick={() => setRapPopup(false)}>
                &#10005;
              </span>

              <h4>Simple</h4>
              <ul>
                <li className="simple">Numéro</li>
                <li className="simple">Gagnant</li>
                <li className="simple">Placé</li>
              </ul>
              <ul>
                <li className="simple">{cr2R.cr2_ar1eq}</li>
                <li className="simple">{cr2R.ra2_s1geq}</li>
                <li className="simple">{cr2R.ra2_s1peq}</li>
              </ul>
              <ul>
                <li className="simple">{cr2R.cr2_ar2eq}</li>
                <li className="simple"></li>
                <li className="simple">{cr2R.ra2_s2peq}</li>
              </ul>
              <ul>
                <li className="simple">{cr2R.cr2_ar3eq}</li>
                <li className="simple"></li>
                <li className="simple">{cr2R.ra2_s3peq}</li>
              </ul>

              <h4>{cr2R.cr2_nbp > 9 ? "2sur4" : "Super 4"}</h4>
              <ul>
                <li className="sup4ou2s4ouTrio">Numéro</li>
                <li className="sup4ou2s4ouTrio">
                  {cr2R.cr2_nbp > 9 ? "2sur4" : "Super 4"}
                </li>
              </ul>
              <ul>
                <li className="sup4ou2s4ouTrio">
                  {cr2R.cr2_ar1eq}-{cr2R.cr2_ar2eq}-{cr2R.cr2_ar3eq}-
                  {cr2R.cr2_ar4eq}
                </li>
                <li className="sup4ou2s4ouTrio">
                  {numDecimal(cr2R.ra2_2s4eq)}
                </li>
              </ul>

              {cr2R.cr2_nbp > 9 && (
                <>
                  <h4>Multi</h4>
                  <ul>
                    <li className="multi">Numéro</li>
                    <li className="multi">En 4</li>
                    <li className="multi">En 5</li>
                    <li className="multi">En 6</li>
                    <li className="multi">{cr2R.cr2_nbp > 13 ? "En 7" : ""}</li>
                  </ul>
                  <ul>
                    <li className="multi">
                      {cr2R.cr2_ar1eq}-{cr2R.cr2_ar2eq}-{cr2R.cr2_ar3eq}-
                      {cr2R.cr2_ar4eq}
                    </li>
                    <li className="multi">{numDecimal(cr2R.ra2_mu4eq)}</li>
                    <li className="multi">{numDecimal(cr2R.ra2_mu5eq)}</li>
                    <li className="multi">{numDecimal(cr2R.ra2_mu6eq)}</li>
                    <li className="multi">
                      {cr2R.cr2_nbp > 13 ? numDecimal(cr2R.ra2_mu7eq) : ""}
                    </li>
                  </ul>
                </>
              )}

              {cr2R.cr2_qui !== "O" ? (
                <>
                  <h4>Trio</h4>
                  <ul>
                    <li className="sup4ou2s4ouTrio">Numéro</li>
                    <li className="sup4ou2s4ouTrio">Trio</li>
                  </ul>
                  <ul>
                    <li className="sup4ou2s4ouTrio">
                      {cr2R.cr2_ar1eq}-{cr2R.cr2_ar2eq}-{cr2R.cr2_ar3eq}
                    </li>
                    <li className="sup4ou2s4ouTrio">
                      {numDecimal(cr2R.ra2_tideq)}
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <h4>Tiercé</h4>
                  <ul>
                    <li className="tierce">Numéro</li>
                    <li className="tierce">Ordre</li>
                    <li className="tierce">Désordre</li>
                  </ul>
                  <ul>
                    <li className="tierce">
                      {cr2R.cr2_ar1eq}-{cr2R.cr2_ar2eq}-{cr2R.cr2_ar3eq}
                    </li>
                    <li className="tierce">{numDecimal(cr2R.ra2_tioeq)}</li>
                    <li className="tierce">{numDecimal(cr2R.ra2_tideq)}</li>
                  </ul>

                  <h4>Quarté</h4>
                  <ul>
                    <li className="quarte">Numéro</li>
                    <li className="quarte">Ordre</li>
                    <li className="quarte">Désordre</li>
                    <li className="quarte">Bonus 3</li>
                  </ul>
                  <ul>
                    <li className="quarte">
                      {cr2R.cr2_ar1eq}-{cr2R.cr2_ar2eq}-{cr2R.cr2_ar3eq}-
                      {cr2R.cr2_ar4eq}
                    </li>
                    <li className="quarte">{numDecimal(cr2R.ra2_q4oeq)}</li>
                    <li className="quarte">{numDecimal(cr2R.ra2_q4deq)}</li>
                    <li className="quarte">{numDecimal(cr2R.ra2_q43eq)}</li>
                  </ul>

                  <h4>Quinté</h4>
                  <ul>
                    <li className="quinte">Numéro</li>
                    <li className="quinte">Ordre</li>
                    <li className="quinte">Désordre</li>
                    <li className="quinte">Bonus 4/5</li>
                    <li className="quinte">Bonus 3</li>
                  </ul>
                  <ul>
                    <li className="quinte">
                      {cr2R.cr2_ar1eq}-{cr2R.cr2_ar2eq}-{cr2R.cr2_ar3eq}-
                      {cr2R.cr2_ar4eq}-{cr2R.cr2_ar5eq}
                    </li>
                    <li className="quinte">{numDecimal(cr2R.ra2_q5oeq)}</li>
                    <li className="quinte">{numDecimal(cr2R.ra2_q5deq)}</li>
                    <li className="quinte">{numDecimal(cr2R.ra2_q54eq)}</li>
                    <li className="quinte">{numDecimal(cr2R.ra2_q53eq)}</li>
                  </ul>
                </>
              )}
            </div>
          </div>
        )}
      </>
    )
  );
};

export default Cr2Compo;
